import instance from './base';

export type CompanyTimeLine = {
  year: number,
  title: string,
  content: string,
}
export type CompanyTimeLineRequest = {
  year: number,
  title: string,
  content: string[],
}

export type CompanyData = {
  name: string,
  slogan: string,
  introduction: string
  timeline: CompanyTimeLine[]
}

export type CompanyOffice = {
  name: string,
  phone: string,
  fax: string,
  address: string,
}

export type CompanyResponseData = {
  uuid: string,
  name: string,
  slogan: string,
  introduction: string,
  description: string,
  seo: string
  timeline: CompanyTimeLineRequest[],

  website: string
  email: string
  offices: CompanyOffice[]
  chinaOffices: CompanyOffice[]
  contactEmail: string,
}

export type CompanyRequestData = {
  uuid: string,
  name: string,
  slogan: string,
  introduction: string
  description: string,
  timeline: CompanyTimeLineRequest[]

  website: string
  email: string
  offices: CompanyOffice[]
  chinaOffices: CompanyOffice[]
  contactEmail: string,
}

export type Business = {
  image: string,
  subImages: string[],
  title: string,
  description: string,
}
export type BussinessResponseData = {
  data: Business[]
}
export type AddBusinessRequest = {
  [key: string]: string | number | string[],
  image: string,
  subImages: string[],
  title: string,
  description: string,
}

const organization = 'MTY5NDQ0NjAzNTgwOTkzNTM2MA==';
const company = 'd93de4ac-a06b-5c81-b2f5-50c64b771683';

// eslint-disable-next-line max-len
export async function updateCompany(companyUUID: string, companyData: CompanyRequestData): Promise<any> {
  const path = `/company/${companyUUID}`;
  const rsp = await instance.post(path, companyData);
  return rsp.data.data;
}

export async function getCompanyData(): Promise<CompanyResponseData> {
  const rsp = await instance.get('/company/');
  return rsp.data.data;
}

export async function getCompanyBusiness(): Promise<BussinessResponseData> {
  const rsp = await instance.get('/company/business', {
    headers: {
      'X-Organization': organization,
    },
  });
  return rsp.data;
}

// eslint-disable-next-line max-len
export async function updateCompanyBussiness(data: Business[]): Promise<boolean> {
  const path = `/company/${company}/business`;
  await instance.put(path, data);
  return true;
}

export type CustomData = {
  [key: string]: string | number,
}

export type Project = {
  id: string,
  no: string,
  visible: boolean,
  image: string,
  // eslint-disable-next-line camelcase
  customer_name: string,
  location: string,
  industry: string,
  items: string[],
  custom: CustomData,
}

export type ProjectResponseData = {
  projects: Project[],
  total: number,
}

export type AddProjectRequest = {
  [key: string]: string | number | boolean | string[] | CustomData,
  no: string,
  visible: boolean,
  image: string,
  // eslint-disable-next-line camelcase
  customer_name: string,
  location: string,
  industry: string,
  items: string[],
  custom: CustomData,

  category: string,
}

// eslint-disable-next-line max-len
export async function getCompanyProject(category: string, page: number, limit: number): Promise<ProjectResponseData> {
  const rsp = await instance.get('/company/projects', {
    headers: {
      'X-Organization': organization,
    },
    params: {
      category,
      page,
      limit,
    },
  });
  return rsp.data.data;
}

export async function addCompanyProject(data: Project): Promise<boolean> {
  const path = `/company/${company}/projects`;
  await instance.post(path, data);
  return true;
}

export async function udpateCompanyProject(projectID: string, data: Project): Promise<boolean> {
  const path = `/company/${company}/project/${projectID}`;
  await instance.put(path, data);
  return true;
}

export async function deleteCompanyProject(projectID: string): Promise<boolean> {
  const path = `/company/${company}/project/${projectID}`;
  await instance.delete(path);
  return true;
}

export type SEOResult = {
  [key: string]: string
}

export async function getSEO(): Promise<SEOResult> {
  const rsp = await instance.get('/company/seo', {
    headers: {
      'X-Organization': organization,
    },
  });
  return rsp.data.data;
}

export async function updateSEO(data: SEOResult): Promise<boolean> {
  const path = `/company/${company}/seo`;
  await instance.post(path, data);
  return true;
}

export type ContactInfo = {
  // eslint-disable-next-line camelcase
  company_name: string,
  mobile: string,
  contact: string,
  email: string,
  service: string[],
  memo: string,
  // eslint-disable-next-line camelcase
  create_time: number,
}

export type ContactInfoResponseData = {
  infos: ContactInfo[],
  total: number,
}

export async function getContacts(page: number, limit: number): Promise<ContactInfoResponseData> {
  const rsp = await instance.get(`/company/${company}/contact`, {
    headers: {
      'X-Organization': organization,
    },
    params: {
      page,
      limit,
    },
  });
  return rsp.data.data;
}

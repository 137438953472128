import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString } from "vue"

const _hoisted_1 = { class: "users p-20 flex column gap-12" }
const _hoisted_2 = { class: "ops flex align-center" }
const _hoisted_3 = { key: 0 }
const _hoisted_4 = { key: 1 }
const _hoisted_5 = { key: 2 }
const _hoisted_6 = { class: "ops flex align-center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_button = _resolveComponent("el-button")!
  const _component_el_table_column = _resolveComponent("el-table-column")!
  const _component_el_popconfirm = _resolveComponent("el-popconfirm")!
  const _component_el_table = _resolveComponent("el-table")!
  const _component_dynamic_form = _resolveComponent("dynamic-form")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_el_button, {
        type: "primary",
        onClick: _ctx.showAdd
      }, {
        default: _withCtx(() => [
          _createTextVNode("新增使用者")
        ]),
        _: 1
      }, 8, ["onClick"])
    ]),
    _createVNode(_component_el_table, {
      class: "flex-1",
      data: _ctx.users
    }, {
      default: _withCtx(() => [
        _createVNode(_component_el_table_column, {
          prop: "account",
          label: "帳號"
        }),
        _createVNode(_component_el_table_column, { label: "類型" }, {
          default: _withCtx((scope) => [
            (scope.row.type === 0)
              ? (_openBlock(), _createElementBlock("span", _hoisted_3, "系統管理員"))
              : (scope.row.type === 1)
                ? (_openBlock(), _createElementBlock("span", _hoisted_4, "組織管理員"))
                : (_openBlock(), _createElementBlock("span", _hoisted_5, "一般使用者 (" + _toDisplayString(scope.row.type) + ")", 1))
          ]),
          _: 1
        }),
        _createVNode(_component_el_table_column, { label: "狀態" }, {
          default: _withCtx((scope) => [
            _createElementVNode("span", null, _toDisplayString(scope.row.status), 1)
          ]),
          _: 1
        }),
        _createVNode(_component_el_table_column, { label: "操作" }, {
          default: _withCtx((scope) => [
            _createElementVNode("div", _hoisted_6, [
              _createVNode(_component_el_popconfirm, {
                "confirm-button-text": "確認",
                "cancel-button-text": "取消",
                title: "確認要刪除該使用者嗎",
                onConfirm: ($event: any) => (_ctx.deleteUser(scope.row.uuid))
              }, {
                reference: _withCtx(() => [
                  _createVNode(_component_el_button, {
                    type: "danger",
                    size: "mini",
                    icon: _ctx.Delete
                  }, null, 8, ["icon"])
                ]),
                _: 2
              }, 1032, ["onConfirm"])
            ])
          ]),
          _: 1
        })
      ]),
      _: 1
    }, 8, ["data"]),
    _createVNode(_component_dynamic_form, {
      ref: "form",
      title: "新增使用者",
      columns: _ctx.userColumns,
      onFinish: _ctx.handleAdd
    }, null, 8, ["columns", "onFinish"])
  ]))
}
import axios from 'axios';

const instance = axios.create({
  baseURL: 'https://bat.bearathome.com.tw/api',
  // baseURL: 'http://localhost:8000',
  withCredentials: true,
  timeout: 30000,
});

instance.interceptors.request.use(
  (config) => {
    config.headers.set('Authorization', `Bearer ${localStorage.getItem('bat-token')}`);
    return config;
  },
  (error) => Promise.reject(error),
);

instance.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response && error.response.status === 401) {
      if (window.location.pathname.indexOf('/login') < 0) {
        window.location.href = '/login';
      }
      return Promise.reject(error);
    }
    return Promise.reject(error);
  },
);

export const organization = process.env.VUE_APP_ORGANIZATION;
console.log(process.env.VUE_APP_ORGANIZATION);

export default instance;

import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, withCtx as _withCtx, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-7e14d16f"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "users p-20 flex column gap-12" }
const _hoisted_2 = { class: "full-page flex column p-20 overflow-hidden" }
const _hoisted_3 = {
  class: "flex-1 flex align-stretch overflow-hidden",
  label: ""
}
const _hoisted_4 = { class: "list flex column overflow-hidden gap-20 overflow-hidden" }
const _hoisted_5 = { class: "ops flex align-center gap-8" }
const _hoisted_6 = { class: "business-list flex-1 overflow-overlay flex column overflow-scroll gap-60" }
const _hoisted_7 = { class: "left-image" }
const _hoisted_8 = ["src"]
const _hoisted_9 = {
  key: 1,
  class: "empty-image"
}
const _hoisted_10 = { class: "right-input flex column flex-1 gap-12 flex-auto" }
const _hoisted_11 = { class: "text nowrap-text text-align-left title" }
const _hoisted_12 = {
  key: 0,
  class: "text-align-left"
}
const _hoisted_13 = {
  key: 1,
  class: "text-align-left"
}
const _hoisted_14 = { class: "flex gap-20" }
const _hoisted_15 = { class: "paginator" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_option = _resolveComponent("el-option")!
  const _component_el_select = _resolveComponent("el-select")!
  const _component_el_button = _resolveComponent("el-button")!
  const _component_el_popconfirm = _resolveComponent("el-popconfirm")!
  const _component_el_pagination = _resolveComponent("el-pagination")!
  const _component_dynamic_form = _resolveComponent("dynamic-form")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("div", _hoisted_5, [
            _createVNode(_component_el_select, {
              modelValue: _ctx.category,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.category) = $event)),
              clearable: ""
            }, {
              default: _withCtx(() => [
                _createVNode(_component_el_option, {
                  label: "全部",
                  value: ""
                }),
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.industryOptions, (opt) => {
                  return (_openBlock(), _createBlock(_component_el_option, {
                    key: opt.value,
                    label: opt.label,
                    value: opt.value
                  }, null, 8, ["label", "value"]))
                }), 128))
              ]),
              _: 1
            }, 8, ["modelValue"]),
            _createVNode(_component_el_button, {
              type: "primary",
              onClick: _ctx.search
            }, {
              default: _withCtx(() => [
                _createTextVNode("搜尋")
              ]),
              _: 1
            }, 8, ["onClick"]),
            _createVNode(_component_el_button, {
              type: "primary",
              onClick: _ctx.addProject
            }, {
              default: _withCtx(() => [
                _createTextVNode("新增工程實績")
              ]),
              _: 1
            }, 8, ["onClick"])
          ]),
          _createElementVNode("div", _hoisted_6, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.projects, (data, idx) => {
              return (_openBlock(), _createElementBlock("div", {
                class: "business-item flex align-center gap-20 p-8 flex-auto",
                key: idx
              }, [
                _createElementVNode("div", _hoisted_7, [
                  (data.imageURL !== '')
                    ? (_openBlock(), _createElementBlock("img", {
                        key: 0,
                        src: data.imageURL
                      }, null, 8, _hoisted_8))
                    : (_openBlock(), _createElementBlock("div", _hoisted_9))
                ]),
                _createElementVNode("div", _hoisted_10, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.projectShowColumn, (col) => {
                    return (_openBlock(), _createElementBlock("div", {
                      class: "flex gap-20 align-center",
                      key: col.key
                    }, [
                      _createElementVNode("div", _hoisted_11, _toDisplayString(col.name), 1),
                      (col.key === 'industry' && data[col.key] === '生技廠')
                        ? (_openBlock(), _createElementBlock("span", _hoisted_12, "生技/食品廠"))
                        : (_openBlock(), _createElementBlock("span", _hoisted_13, _toDisplayString(data[col.key]), 1))
                    ]))
                  }), 128)),
                  _createElementVNode("div", _hoisted_14, [
                    _createVNode(_component_el_popconfirm, {
                      "confirm-button-text": "確認",
                      "cancel-button-text": "取消",
                      title: "確認要刪除該項工程實績？",
                      onConfirm: ($event: any) => (_ctx.deleteProject(data))
                    }, {
                      reference: _withCtx(() => [
                        _createVNode(_component_el_button, { type: "danger" }, {
                          default: _withCtx(() => [
                            _createTextVNode("刪除")
                          ]),
                          _: 1
                        })
                      ]),
                      _: 2
                    }, 1032, ["onConfirm"]),
                    _createVNode(_component_el_button, {
                      onClick: ($event: any) => (_ctx.showEdit(data))
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode("編輯")
                      ]),
                      _: 2
                    }, 1032, ["onClick"])
                  ])
                ])
              ]))
            }), 128))
          ]),
          _createElementVNode("div", _hoisted_15, [
            _createVNode(_component_el_pagination, {
              "current-page": _ctx.page,
              "onUpdate:currentPage": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.page) = $event)),
              "page-size": _ctx.limit,
              "onUpdate:pageSize": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.limit) = $event)),
              layout: "prev, pager, next, sizes, total",
              total: _ctx.total
            }, null, 8, ["current-page", "page-size", "total"])
          ])
        ])
      ])
    ]),
    _createVNode(_component_dynamic_form, {
      ref: "addForm",
      title: "新增工程實績",
      columns: _ctx.projectColumns,
      onFinishEdit: _ctx.handleEdit,
      onFinish: _ctx.handleAdd
    }, null, 8, ["columns", "onFinishEdit", "onFinish"])
  ]))
}
/* eslint-disable no-param-reassign */
/* eslint-disable max-len */
import { App, Plugin, getCurrentInstance } from 'vue';
import { ElLoading, ElNotification } from 'element-plus';
import axios, { AxiosError } from 'axios';
import i18n from './i18n';

const { t } = i18n.global;

export interface ILoadModule {
  $execWithLoading: (msg: string) => Promise<any>
}

export interface LoadModuleOptions {
  dftMsg: string
}

interface RawResponse {
  code: string
}

export const loadPlugin: Plugin = {
  install(app: App, options: LoadModuleOptions | undefined) {
    const loadingWithMessage = async function (msg: string, func: () => void, errFunc: (arg0: any) => void):Promise<any> {
      let showMsg = msg;
      if (showMsg === '') {
        showMsg = options?.dftMsg || '';
      }
      const instance = getCurrentInstance()?.appContext.config.globalProperties;
      const loading = ElLoading.service({
        lock: true,
        text: 'Loading',
        background: 'rgba(0, 0, 0, 0.7)',
      });
      let promise = new Promise((r) => {
        r(undefined);
      });

      return new Promise((r) => {
        setTimeout(async () => {
          promise = promise.then(() => func.apply(instance));
          try {
            await promise;
          } catch (e) {
            console.log(`loading exception: ${e}`);
            if (errFunc !== undefined) {
              try {
                errFunc(e);
              } catch (e2) {
                console.log(e2);
              }
            }
          }
          loading?.close();
          r(undefined);
        }, 10);
      });
    };

    app.config.globalProperties.$execWithLoading = function (func: () => void, errFunc: (arg0: any) => void):Promise<any> {
      return loadingWithMessage('Loading', func, errFunc);
    };
    app.config.globalProperties.$showAxiosException = function (e: Error | AxiosError) {
      let msg;
      const instance = getCurrentInstance()?.appContext.config.globalProperties;
      if (axios.isAxiosError(e)) {
        const err = e as AxiosError;
        if (err.response?.status === axios.HttpStatusCode.Unauthorized) {
          instance?.$router.push('/login');
        }
        const rsp = err.response?.data as RawResponse;
        const errKey = `error.${rsp.code || 'unknown'}`;
        msg = t(errKey) || 'Unknown error';
      } else {
        msg = e.toString();
      }
      ElNotification({
        title: msg,
        type: 'error',
      });
    };
  },
};


import { ElNotification } from 'element-plus';
import { defineComponent } from 'vue';
import { AxiosError } from 'axios';
import {
  Project,
  AddProjectRequest,
  CustomData,
  getCompanyProject,
  addCompanyProject,
  deleteCompanyProject,
  udpateCompanyProject,
} from '@/api/company';
import {
  ColumnType, Value, InputValue, SelectItem,
} from '@/components/drawer/dynamicColumn';
import dynamicForm from '@/components/drawer/dynamicForm.vue';

  type ProjectShowed = {
    [key: string]: string | number | string[] | boolean | CustomData,
    id: string,
    no: string,
    visible: boolean,
    image: string,
    imageURL: string,
    // eslint-disable-next-line camelcase
    customer_name: string,
    location: string,
    industry: string,
    items: string[],
    showItems: string,
    custom: CustomData,
    level: string,
  }

export default defineComponent({
  components: {
    'dynamic-form': dynamicForm,
  },
  props: {
    organization: {
      type: String,
      default: '',
    },
  },
  data() {
    const projects: ProjectShowed[] = [];
    const industryOptions: SelectItem[] = [
      { value: '無塵衣物專業清洗廠', label: '無塵衣物專業清洗廠' },
      { value: '飯店商辦', label: '飯店商辦' },
      { value: '學術機關', label: '學術機關' },
      { value: '科技廠', label: '科技廠' },
      { value: '太陽能廠', label: '太陽能廠' },
      { value: '生技廠', label: '生技/食品廠' }];
    return {
      projects,
      page: 1,
      limit: 10,
      total: 0,
      category: '',
      editing: '',

      industryOptions,
      projectShowColumn: [
        { key: 'no', name: 'ID' },
        { key: 'customer_name', name: '公司名稱' },
        { key: 'location', name: '地點' },
        { key: 'industry', name: '產業類別' },
        { key: 'showItems', name: '工程項目' },
        { key: 'level', name: '無塵室等級' },
      ],

      projectColumns: [
        {
          key: 'image',
          name: '圖片',
          type: ColumnType.Image,
          options: undefined,
        },
        {
          key: 'no',
          name: 'ID',
          type: ColumnType.Text,
          options: undefined,
        },
        {
          key: 'customer_name',
          name: '公司名稱',
          type: ColumnType.Text,
          options: undefined,
        },
        {
          key: 'location',
          name: '地點',
          type: ColumnType.Text,
          options: undefined,
        },
        {
          key: 'industry',
          name: '產業類別',
          type: ColumnType.Select,
          options: industryOptions,
        },
        {
          key: 'items',
          name: '工程項目',
          type: ColumnType.MultiText,
          options: undefined,
        },
        {
          key: 'level',
          name: '無塵室等級',
          type: ColumnType.Text,
          options: undefined,
        },
      ],
    };
  },
  watch: {
    limit() {
      this.loadData();
    },
    page() {
      this.loadData();
    },
  },
  methods: {
    search() {
      this.loadData();
    },
    loadData() {
      this.$execWithLoading(async () => {
        await this.loadProjects();
      }, (e: Error | AxiosError) => {
        this.$showAxiosException(e);
      });
    },
    async loadProjects() {
      const rsp = await getCompanyProject(this.category, this.page, this.limit);
      this.projects = rsp.projects.map((d) => ({
        ...d,
        custom: d.custom || {},
        imageURL: d.image === '' ? '' : `https://bat.bearathome.com.tw/api/image/${d.image}`,
        showItems: d.items.join(','),
        level: (d?.custom?.['無塵室等級'] || '').toString(),
      }));
      this.total = rsp.total;
    },
    addProject() {
      (this.$refs.addForm as typeof dynamicForm).show();
    },
    showEdit(project: Project) {
      const values: InputValue[] = [
        { key: 'id', value: project.id },
        { key: 'no', value: project.no },
        { key: 'image', value: project.image },
        { key: 'customer_name', value: project.customer_name },
        { key: 'location', value: project.location },
        { key: 'industry', value: project.industry },
        { key: 'items', value: project.items },
        { key: 'level', value: project.custom['無塵室等級'] },
        { key: 'category', value: project.industry },
      ];
      this.editing = project.id;
      (this.$refs.addForm as typeof dynamicForm).show(values);
    },
    handleEdit(data: Value[]) {
      const project: AddProjectRequest = {
        no: '',
        visible: true,
        image: '',
        // eslint-disable-next-line camelcase
        customer_name: '',
        location: '',
        industry: '',
        items: [],
        custom: {},
        category: '',
      };
      data.forEach((d) => {
        if (d.key === 'level') {
          project.custom['無塵室等級'] = d.value as (string);
          return;
        }
        if (d.key in project) {
          project[d.key] = d.value as (string | number | boolean | string[] | CustomData);
        }
      });
      console.log({ data, project });
      const input = {
        id: '',
        no: project.no,
        visible: project.visible,
        image: project.image,
        customer_name: project.customer_name,
        location: project.location,
        industry: project.industry,
        items: project.items,
        custom: project.custom,
        category: project.industry,
      };
      this.$execWithLoading(async () => {
        await udpateCompanyProject(this.editing, input);
        (this.$refs.addForm as typeof dynamicForm).close();
        await this.loadProjects();
      }, (e: Error) => {
        this.$showAxiosException(e);
      });
      return true;
    },
    handleAdd(data: Value[]) {
      const project: AddProjectRequest = {
        no: '',
        visible: true,
        image: '',
        // eslint-disable-next-line camelcase
        customer_name: '',
        location: '',
        industry: '',
        items: [],
        custom: {},
        category: '',
      };
      data.forEach((d) => {
        if (d.key === 'level') {
          project.custom['無塵室等級'] = d.value as (string);
          return;
        }
        if (d.key in project) {
          project[d.key] = d.value as (string | number | boolean | string[] | CustomData);
        }
      });
      console.log({ data, project });
      const input = {
        id: '',
        no: project.no,
        visible: project.visible,
        image: project.image,
        customer_name: project.customer_name,
        location: project.location,
        industry: project.industry,
        items: project.items,
        custom: project.custom,
        category: project.industry,
      };
      this.$execWithLoading(async () => {
        await addCompanyProject(input);
        (this.$refs.addForm as typeof dynamicForm).close();
        await this.loadProjects();
      }, (e: Error) => {
        this.$showAxiosException(e);
      });
      return true;
    },
    deleteProject(project: Project) {
      this.$execWithLoading(async () => {
        await deleteCompanyProject(project.id);
        await this.loadProjects();
      }, (e: Error) => {
        this.$showAxiosException(e);
      });
    },
  },
  mounted() {
    this.loadData();
  },
});

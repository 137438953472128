import instance from './base';

export async function deleteImage(id: string): Promise<boolean> {
  await instance.delete(`/image/${id}`);
  return true;
}

export function getImageURL(id: string) : string {
  return `https://bat.bearathome.com.tw/api/image/${id}`;
}

// eslint-disable-next-line no-shadow
export enum ColumnType {
  Text,
  Select,
  Password,
  Image,
  Images,
  MultiText,
}

export interface SelectItem {
  value: string | number,
  label: string,
}

export interface Column {
  key: string
  name: string
  type: ColumnType
  options: SelectItem[] | undefined
}

export interface Value extends Column {
  //* * Value of corresponding column */
  value: string | number | File | string[],
}

export interface InputValue {
  key: string
  value: string | number | File | string[],
}

import { createApp } from 'vue';
import ElementPlus from 'element-plus';
import locale from 'element-plus/lib/locale/lang/zh-tw';
import { loadPlugin } from '@/plugins/request';
import notifyPlugin from '@/plugins/notify';
import i18n from './plugins/i18n';
import App from './App.vue';
import router from './router';
import store from './store';

import '@/assets/style/element-style.scss';
// import 'element-plus/dist/index.css';
import '@/assets/style/global.scss';

createApp(App)
  . use(store)
  . use(router)
  . use(i18n)
  . use(ElementPlus, { locale })
  . use(loadPlugin)
  . use(notifyPlugin)
  . mount('#app');

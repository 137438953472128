import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router';
import LoginView from '@/views/LoginView.vue';
import UsersView from '@/views/UsersView.vue';
import CompanyView from '@/views/CompanyView.vue';
import ProjectView from '@/views/ProjectView.vue';
import BussinessView from '@/views/BusinessView.vue';
import SEOView from '@/views/SEOView.vue';
import ContactInfoView from '@/views/ContactInfoView.vue';
import ContactView from '@/views/ContactView.vue';

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    redirect: '/login',
  },
  {
    path: '/login',
    name: 'login',
    component: LoginView,
  },
  {
    path: '/users',
    name: 'Users',
    component: UsersView,
  },
  {
    path: '/company',
    name: 'Company',
    component: CompanyView,
  },
  {
    path: '/contact-info',
    name: 'ContactInfo',
    component: ContactInfoView,
  },
  {
    path: '/business',
    name: 'Bussiness',
    component: BussinessView,
  },
  {
    path: '/project',
    name: 'Project',
    component: ProjectView,
  },
  {
    path: '/seo',
    name: 'SEO',
    component: SEOView,
  },
  {
    path: '/contacts',
    name: 'Contacts',
    component: ContactView,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;


import { defineComponent } from 'vue';
import {
  getContacts,
  ContactInfo,
} from '@/api/company';
import { AxiosError } from 'axios';

function paddingToN(num: number, n: number): string {
  if (num.toString().length >= n) {
    return num.toString();
  }
  const zeroCount = n - num.toString().length;
  const ret = String('0');
  return `${ret.repeat(zeroCount)}${num}`;
}

function timestampToDateTime(timestamp: number): string {
  let useTime = timestamp;
  if (useTime.toString().length <= 10) {
    useTime *= 1000;
  }
  const d = new Date(useTime);

  if (Number.isNaN(d.getTime())) {
    return '';
  }
  return `${
    paddingToN(d.getFullYear(), 4)
  }/${
    paddingToN(d.getMonth() + 1, 2)
  }/${
    paddingToN(d.getDate(), 2)
  } ${
    paddingToN(d.getHours(), 2)
  }:${
    paddingToN(d.getMinutes(), 2)
  }:${
    paddingToN(d.getSeconds(), 2)
  }`;
}

export default defineComponent({
  data() {
    const infos: ContactInfo[] = [];
    return {
      page: 1,
      limit: 20,
      total: 20,

      infos,
    };
  },
  watch: {
    limit() {
      this.loadData();
    },
    page() {
      this.loadData();
    },
  },
  methods: {
    timestampToDateTime,
    loadData() {
      this.$execWithLoading(async () => {
        await this.loadContactInfos();
      }, (e: Error | AxiosError) => {
        this.$showAxiosException(e);
      });
    },
    async loadContactInfos() {
      const infoDatas = await getContacts(this.page, this.limit);
      this.infos = infoDatas.infos;
      this.total = infoDatas.total;
    },
  },
  mounted() {
    this.loadData();
  },
});

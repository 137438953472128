
import { ElNotification } from 'element-plus';
import {
  UploadFilled, Delete, CaretLeft, CaretRight,
} from '@element-plus/icons-vue';
import { defineComponent } from 'vue';
import { AxiosError } from 'axios';
import {
  Business,
  AddBusinessRequest,
  getCompanyBusiness,
  updateCompanyBussiness,
} from '@/api/company';
import { ColumnType, Value } from '@/components/drawer/dynamicColumn';
import dynamicForm from '@/components/drawer/dynamicForm.vue';
import { deleteImage } from '@/api/image';

type BusinessShowed = {
  image: string,
  subImages: string[],
  imageURL: string,
  subImageURLs: string[],
  title: string,
  description: string,
}

export default defineComponent({
  components: {
    'dynamic-form': dynamicForm,
    UploadFilled,
  },
  props: {
    organization: {
      type: String,
      default: '',
    },
  },
  data() {
    const business: BusinessShowed[] = [];
    return {
      form: {
        name: '',
        slogan: '',
        introduction: '',
      },
      business,
      businessColumns: [
        {
          key: 'image',
          name: '圖片',
          type: ColumnType.Images,
          options: undefined,
        },
        {
          key: 'title',
          name: '標題',
          type: ColumnType.Text,
          options: undefined,
        },
        {
          key: 'description',
          name: '說明',
          type: ColumnType.Text,
          options: undefined,
        },
      ],
      editMode: false,
      uploadHeader: {
        Authorization: `Bearer ${localStorage.getItem('bat-token')}`,
      },
      Delete,
      CaretLeft,
      CaretRight,
    };
  },
  methods: {
    goRight(idx: number, subIdx: number) {
      const target = this.business[idx];
      if (subIdx >= target.subImages.length - 1) {
        return;
      }
      if (target.subImages.length === 0) {
        return;
      }
      const tmp = target.subImages[subIdx + 1];
      const tmpURL = target.subImageURLs[subIdx + 1];
      if (subIdx === -1) {
        target.subImages[subIdx + 1] = target.image;
        target.subImageURLs[subIdx + 1] = target.imageURL;
        target.image = tmp;
        target.imageURL = tmpURL;
      } else {
        target.subImages[subIdx + 1] = target.subImages[subIdx];
        target.subImageURLs[subIdx + 1] = target.subImageURLs[subIdx];
        target.subImages[subIdx] = tmp;
        target.subImageURLs[subIdx] = tmpURL;
      }
    },
    goLeft(idx: number, subIdx: number) {
      const target = this.business[idx];
      if (subIdx === -1) {
        return;
      }
      const tmp = target.subImages[subIdx];
      const tmpURL = target.subImageURLs[subIdx];
      if (subIdx <= 0) {
        target.subImages[subIdx] = target.image;
        target.subImageURLs[subIdx] = target.imageURL;
        target.image = tmp;
        target.imageURL = tmpURL;
      } else {
        target.subImages[subIdx] = target.subImages[subIdx - 1];
        target.subImageURLs[subIdx] = target.subImageURLs[subIdx - 1];
        target.subImages[subIdx - 1] = tmp;
        target.subImageURLs[subIdx - 1] = tmpURL;
      }
    },
    async deleteImage(id: string) {
      try {
        await deleteImage(id);
      } catch (e) {
        console.log(e);
      }
    },
    removeMainImage(idx: number) {
      const target = this.business[idx];
      const removeID = target.image;
      this.deleteImage(removeID);

      if (this.business[idx].subImages.length > 0) {
        [target.imageURL] = this.business[idx].subImageURLs;
        [target.image] = this.business[idx].subImages;
        this.business[idx].subImageURLs.shift();
        this.business[idx].subImages.shift();
      } else {
        target.imageURL = '';
        target.image = '';
      }
    },
    removeSubImage(idx: number, subIdx: number) {
      const target = this.business[idx];
      const removeID = target.subImages[subIdx];
      this.deleteImage(removeID);
      target.subImageURLs.splice(subIdx, 1);
      target.subImages.splice(subIdx, 1);
    },
    genHandleUploadSuccess(idx: number) {
      return (file: any, uploadFiles: any) => {
        console.log('handleUploadSuccess', file, uploadFiles, idx);
        const url = `https://bat.bearathome.com.tw/api/image/${file.data}`;
        if (this.business[idx].imageURL === '') {
          this.business[idx].image = file.data;
          this.business[idx].imageURL = url;
        } else {
          this.business[idx].subImages.push(file.data);
          this.business[idx].subImageURLs.push(url);
        }
        (this.$refs.uploader as Array<any>).forEach((uploader) => {
          uploader.clearFiles();
        });
      };
    },
    goUp(idx: number) {
      if (idx === 0) {
        return;
      }
      const tmp = this.business[idx - 1];
      this.business[idx - 1] = this.business[idx];
      this.business[idx] = tmp;
      this.$forceUpdate();
    },
    remove(idx: number) {
      this.business.splice(idx, 1);
      this.$forceUpdate();
    },
    goDown(idx: number) {
      if (idx > this.business.length - 1) {
        return;
      }
      const tmp = this.business[idx + 1];
      this.business[idx + 1] = this.business[idx];
      this.business[idx] = tmp;
      this.$forceUpdate();
    },
    startEdit() {
      this.editMode = true;
    },
    loadData() {
      this.$execWithLoading(async () => {
        await this.loadBusiness();
      }, (e: Error | AxiosError) => {
        this.$showAxiosException(e);
      });
    },
    async loadBusiness() {
      const rsp = await getCompanyBusiness();
      this.business = rsp.data.map((d) => ({
        ...d,
        imageURL: d.image === '' ? '' : `https://bat.bearathome.com.tw/api/image/${d.image}`,
        subImageURLs: (d.subImages || []).map((id) => (id === '' ? '' : `https://bat.bearathome.com.tw/api/image/${id}`)),
      }));
    },
    addBusiness() {
      this.$execWithLoading(async () => {
        console.log(this.$refs.addForm);
        (this.$refs.addForm as typeof dynamicForm).show();
      }, (e: Error) => {
        this.$showAxiosException(e);
      });
    },
    handleAdd(data: Value[]) {
      const business: AddBusinessRequest = {
        image: '',
        subImages: [],
        title: '',
        description: '',
      };
      data.forEach((d) => {
        if (d.key in business) {
          if (d.key === 'image') {
            const values = d.value as string[];
            console.log('image', values);
            if (values.length > 0) {
              [business.image] = values;
              business.subImages = values.slice(1);
            }
            return;
          }
          business[d.key] = d.value as (string | number | string[]);
        }
      });
      const req = this.business.map((b) => ({
        image: b.image,
        subImages: b.subImages,
        title: b.title,
        description: b.description,
      } as Business));
      req.unshift({
        image: business.image,
        subImages: business.subImages,
        title: business.title,
        description: business.description,
      });
      this.$execWithLoading(async () => {
        await updateCompanyBussiness(req);
        console.log(req);
        await this.loadBusiness();
        (this.$refs.addForm as typeof dynamicForm).close();
      }, (e: Error) => {
        this.$showAxiosException(e);
      });
      return true;
    },
    save() {
      const req = this.business.map((b) => ({
        image: b.image,
        subImages: b.subImages,
        title: b.title,
        description: b.description,
      } as Business));
      this.$execWithLoading(async () => {
        await updateCompanyBussiness(req);
        this.editMode = false;
        await this.loadBusiness();
      }, (e: Error) => {
        this.$showAxiosException(e);
      });
    },
    cancel() {
      this.editMode = false;
      this.loadData();
    },
  },
  mounted() {
    this.loadData();
  },
});


import { ElNotification } from 'element-plus';
import { defineComponent } from 'vue';
import { AxiosError } from 'axios';
import {
  CompanyRequestData,
  CompanyResponseData,
  getCompanyData,
  updateCompany,
} from '@/api/company';
import { Delete } from '@element-plus/icons-vue';

  type CompanyTimeLineInput = {
    year: string,
    title: string,
    content: string,
  }

export default defineComponent({
  props: {
    organization: {
      type: String,
      default: '',
    },
  },
  data() {
    const timeline: CompanyTimeLineInput[] = [];
    const origData: CompanyResponseData = {
      uuid: '',
      name: '',
      slogan: '',
      introduction: '',
      description: '',
      seo: '',
      contactEmail: '',
      timeline: [],

      website: '',
      email: '',
      offices: [],
      chinaOffices: [],
    };
    return {
      form: {
        name: '',
        slogan: '',
        introduction: '',
        description: '',
        seo: '',
        contactEmail: '',
      },
      timeline,
      origData,
      revert: true,
      Delete,
    };
  },
  methods: {
    deleteTimeline(idx: number) {
      this.timeline.splice(idx, 1);
    },
    loadData() {
      this.$execWithLoading(async () => {
        await this.loadCompanyData();
      }, (e: Error | AxiosError) => {
        this.$showAxiosException(e);
      });
    },
    async loadCompanyData() {
      const rsp = await getCompanyData();
      this.origData = rsp;
      this.form = {
        name: rsp.name,
        slogan: rsp.slogan,
        introduction: rsp.introduction,
        description: rsp.description,
        seo: rsp.seo,
        contactEmail: rsp.contactEmail,
      };
      this.timeline = rsp.timeline.map((t) => ({
        ...t,
        year: t.year.toString(),
        content: t.content.join('\n'),
      }));
    },
    reset() {
      this.timeline = this.origData.timeline.map((t) => ({
        ...t,
        year: t.year.toString(),
        content: t.content.join('\n'),
      }));
    },
    addTimeline() {
      this.timeline.unshift({
        year: (new Date()).getFullYear().toString(),
        title: '',
        content: '說明文字',
      });
    },
    sortTimeline() {
      const timeline = this.timeline.map((t) => ({
        ...t,
        year: parseInt(t.year, 10),
      }));
      timeline.sort((t1, t2) => (t1.year - t2.year) * (this.revert ? -1 : 1));
      this.timeline = timeline.map((t) => ({
        ...t,
        year: t.year.toString(),
      }));
    },
    async save() {
      const data: CompanyRequestData = {
        ...this.form,
        uuid: this.origData.uuid,
        timeline: this.timeline.map((t) => ({
          ...t,
          year: parseInt(t.year, 10),
          content: t.content.split('\n'),
        })),

        website: this.origData.website,
        email: this.origData.email,
        offices: this.origData.offices,
        chinaOffices: this.origData.chinaOffices,
      };
      this.$execWithLoading(async () => {
        await updateCompany(this.origData.uuid, data);
        ElNotification({
          title: '更新成功',
          type: 'success',
        });
      }, (e: Error) => {
        this.$showAxiosException(e);
      });
    },
  },
  mounted() {
    this.loadData();
  },
});

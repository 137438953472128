
import { ElNotification } from 'element-plus';
import { defineComponent } from 'vue';
import { AxiosError } from 'axios';
import {
  getSEO,
  updateSEO,
} from '@/api/company';

export default defineComponent({
  props: {
    organization: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      form: {
        homeSEO: '',
        introSEO: '',
        businessSEO: '',
        projectsSEO: '',
      },
    };
  },
  methods: {
    loadData() {
      this.$execWithLoading(async () => {
        await this.loadSEO();
      }, (e: Error | AxiosError) => {
        this.$showAxiosException(e);
      });
    },
    async loadSEO() {
      const data = await getSEO();
      this.form.homeSEO = data['/'];
      this.form.businessSEO = data['/business'];
      this.form.introSEO = data['/introduction'];
      this.form.projectsSEO = data['/projects'];
    },
    reset() {
      this.loadData();
    },
    async save() {
      this.$execWithLoading(async () => {
        const data = {
          '/': this.form.homeSEO,
          '/business': this.form.businessSEO,
          '/introduction': this.form.introSEO,
          '/projects': this.form.projectsSEO,
        };
        await updateSEO(data);
        ElNotification({
          title: '更新成功',
          type: 'success',
        });
      }, (e: Error) => {
        this.$showAxiosException(e);
      });
    },
  },
  mounted() {
    this.loadData();
  },
});

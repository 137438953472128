
import { defineComponent } from 'vue';
import { mapGetters, mapMutations } from 'vuex';
import { getProfile } from '@/api/auth';
import { AxiosError } from 'axios';

export default defineComponent({
  data() {
    return {
      menu: '/home',
      ready: false,
    };
  },
  computed: {
    ...mapGetters(['isLogin']),
  },
  methods: {
    ...mapMutations(['setToken', 'setUserInfo']),
    setReady(ready: boolean) {
      this.ready = ready;
    },
    async loadData() {
      try {
        this.setToken(localStorage.getItem('bat-token') || '');
        const profile = await getProfile();
        this.setUserInfo(profile);

        this.menu = this.$route?.fullPath;
      } catch (e: unknown) {
        console.log(e);
      }
      this.setReady(true);
    },
    logout() {
      this.setToken('');
      this.setUserInfo(undefined);
      this.$router.push('/login');
    },
  },
  async mounted() {
    function safariHacks() {
      const windowsVH = window.innerHeight / 100;
      const wrap = document.querySelector('.safari-wrap') as HTMLElement;
      if (wrap) {
        wrap.style.setProperty('--vh', `${windowsVH}px`);
        window.addEventListener('resize', () => {
          wrap.style.setProperty('--vh', `${windowsVH}px`);
        });
      }
    }

    window.addEventListener('resize', () => {
      safariHacks();
    });
    this.loadData();
  },
});

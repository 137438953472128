import instance, { organization } from './base';

type LoginRequest = {
  account: string,
  password: string
}

type UserProfile = {
  account: string,
  uuid: string,
  status: number,
  type: number
}

export async function login(request: LoginRequest) {
  const rsp = await instance.post(`/auth/${organization}/login`, request);
  return rsp.data;
}

export async function getProfile(): Promise<UserProfile> {
  const rsp = await instance.get(`/auth/${organization}/profile`);
  return rsp.data.data;
}

export async function logout() {
  await instance.get('/auth/logout');
}

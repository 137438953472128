
import { defineComponent } from 'vue';
import {
  getUsers, addUser, deleteUser,
  AddUserRequest,
  Organization, User,
} from '@/api/user';
import { AxiosError } from 'axios';
import { Delete } from '@element-plus/icons-vue';
import { ColumnType, Value, SelectItem } from '@/components/drawer/dynamicColumn';
import dynamicForm from '@/components/drawer/dynamicForm.vue';

const systemOrg: SelectItem = {
  value: '',
  label: '系統',
};
interface idNameMap {
  [key: string]: string,
}

export default defineComponent({
  components: {
    'dynamic-form': dynamicForm,
  },
  data() {
    const users: User[] = [];
    const orgOptions: SelectItem[] = [];
    const organizations: Organization[] = [];
    const typeOptions: SelectItem[] = [{
      value: 1,
      label: '組織管理員',
    }, {
      value: 2,
      label: '一般使用者',
    }];
    return {
      Delete,
      users,
      organizations,
      userColumns: [
        {
          key: 'account',
          name: '帳號',
          type: ColumnType.Text,
          options: undefined,
        },
        {
          key: 'password',
          name: '密碼',
          type: ColumnType.Password,
          options: undefined,
        },
        {
          key: 'type',
          name: '類型',
          type: ColumnType.Select,
          options: typeOptions,
        },
      ],
    };
  },
  computed: {
    organizationMap() {
      const ret: idNameMap = {};
      this.organizations.forEach((org) => {
        ret[org.uuid] = org.name;
      });
      return ret;
    },
  },
  methods: {
    loadData() {
      this.$execWithLoading(async () => {
        await this.loadUsers();
      }, (e: Error | AxiosError) => {
        this.$showAxiosException(e);
      });
    },
    async loadUsers() {
      const usersData = await getUsers();
      this.users = usersData.users;
    },
    async showAdd() {
      this.$execWithLoading(async () => {
        (this.$refs.form as typeof dynamicForm).show();
      }, (e: Error) => {
        this.$showAxiosException(e);
      });
    },
    handleAdd(data: Value[]) {
      const user: AddUserRequest = {
        account: '',
        password: '',
        organization: '',
        type: 1,
      };
      data.forEach((d) => {
        if (d.key in user) {
          user[d.key] = d.value as (string | number);
        }
      });
      if (!user.type) {
        this.$showError('error.user.need_type');
        return;
      }
      this.$execWithLoading(async () => {
        await addUser(user);
        await this.loadUsers();
        (this.$refs.form as typeof dynamicForm).close();
      }, (e: Error) => {
        this.$showAxiosException(e);
      });
    },
    deleteUser(user: string) {
      const idx = this.users.findIndex((u) => u.uuid === user);
      if (idx < 0) {
        return;
      }
      const u = this.users[idx];
      this.$execWithLoading(async () => {
        await deleteUser(u.uuid);
        await this.loadUsers();
      }, (e: Error) => {
        this.$showAxiosException(e);
      });
    },
  },
  mounted() {
    this.loadData();
  },
});

import instance, { organization } from './base';

export type AddUserRequest = {
  [key: string]: string | number,
   account: string
   password: string
   organization: string
   type: number
}

export type User = {
  uuid: string
  account: string
  organization: string
  status: number
  type: number
}

type ResponseFormat = {
  data: any
}

type Pager = {
  page: number
  limit: number
}

type UsersResponse = {
  users: User[]
  count: number
  pager: Pager
}

export async function getUsers(): Promise<UsersResponse> {
  const rsp = await instance.get(`/auth/${organization}/users`);
  const data = rsp.data as ResponseFormat;
  return data.data as UsersResponse;
}

export async function addUser(user: AddUserRequest): Promise<string> {
  const rsp = await instance.post(`/auth/${organization}/user`, user);
  const data = rsp.data as ResponseFormat;
  return data.data as string;
}

export async function deleteUser(user: string): Promise<boolean> {
  const url = `/auth${organization !== '' ? (`/${organization}`) : ''}/user/${user}`;
  await instance.delete(url);
  return true;
}

export type Organization = {
  [key: string]: string | number,
  name: string
  enable: number
  uuid: string
}

/* eslint-disable no-param-reassign */
import { createStore } from 'vuex';

export default createStore({
  state: {
    userInfo: undefined,
    token: '',
  },
  getters: {
    isLogin(s) {
      return s.userInfo !== undefined;
    },
  },
  mutations: {
    setToken(s, token) {
      s.token = token;
      localStorage.setItem('bat-token', token);
    },
    setUserInfo(s, info) {
      s.userInfo = info;
    },
  },
  actions: {
  },
  modules: {
  },
});

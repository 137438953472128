// eslint-disable-next-line import/no-extraneous-dependencies
import { createI18n } from 'vue-i18n';
import zh from '../languages/zh-tw.json';
// import en from '../languages/en-us.json';

type MessageSchema = typeof zh

// const i18n = createI18n<[MessageSchema], 'zh-TW' | 'en-US'>({
const i18n = createI18n<[MessageSchema], 'zh-TW' >({
  legacy: false, // 要把 legacy 設為 false，才可以使用 Composition API
  locale: 'zh-TW',
  fallbackLocale: 'zh-TW',
  globalInjection: true,
  messages: {
    'zh-TW': zh,
    // 'en-US': en,
  },
});

export default i18n;


import { ElNotification } from 'element-plus';
import { UploadFilled, Delete } from '@element-plus/icons-vue';
import { defineComponent, PropType } from 'vue';
import {
  Column, ColumnType, Value, InputValue,
} from './dynamicColumn';

export default defineComponent({
  name: 'person-editor',
  components: {
    UploadFilled,
  },
  props: {
    title: {
      type: String,
      require: true,
    },
    appendToBody: {
      type: Boolean,
      default: false,
    },
    columns: {
      type: Array as PropType<Column[]>,
      default: () => [],
    },
  },
  data() {
    const datas: Value[] = [];
    const origValues: InputValue[] = [];
    return {
      drawer: false,
      datas,
      ColumnType,
      uploadHeader: {
        Authorization: `Bearer ${localStorage.getItem('bat-token')}`,
      },
      origValues,
      Delete,
    };
  },
  watch: {
    drawer() {
      if (this.drawer) {
        this.loadData();
      }
    },
  },
  methods: {
    handleClose(done: () => void) {
      done();
    },
    close() {
      this.datas = [];
      this.drawer = false;
    },
    show(values: InputValue[]) {
      this.drawer = true;
      this.origValues = values || [];
    },
    finish() {
      const ret = JSON.parse(JSON.stringify(this.datas));
      if (this.origValues.length !== 0) {
        this.$emit('finishEdit', ret);
      } else {
        this.$emit('finish', ret);
      }
    },
    cancel() {
      this.close();
    },
    loadData() {
      const datas:Value[] = [];
      this.columns.forEach((col) => {
        let value: string | number | File | string[] = '';
        this.origValues.forEach((v) => {
          if (col.key === v.key) {
            // eslint-disable-next-line no-param-reassign
            value = v.value;
          }
        });
        if (value === '' && col.type === ColumnType.MultiText) {
          value = [''];
        } else if (value === '' && col.type === ColumnType.Images) {
          value = [];
        }
        datas.push({
          ...col,
          value,
        });
      });
      this.datas = datas;
    },
    genHandleUploadSuccess(idx: number) {
      return (file: any, uploadFiles: any) => {
        console.log('handleUploadSuccess', file, uploadFiles);
        this.datas[idx].value = file.data;
      };
    },
    genHandleUploadMultiSuccess(idx: number) {
      return (file: any, uploadFiles: any) => {
        console.log('handleUploadSuccess', file, uploadFiles);
        (this.datas[idx].value as string[]).push(file.data);
      };
    },
    handleExceed(file: any, uploadFiles: any) {
      ElNotification({
        title: '請先移除圖片後重新選擇',
        type: 'warning',
      });
    },
  },
  mounted() {
    this.loadData();
  },
});

import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createBlock as _createBlock, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "users p-20 flex column gap-12" }
const _hoisted_2 = { class: "tags flex wrap gap-4" }
const _hoisted_3 = { class: "paginator" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_table_column = _resolveComponent("el-table-column")!
  const _component_el_tag = _resolveComponent("el-tag")!
  const _component_el_table = _resolveComponent("el-table")!
  const _component_el_pagination = _resolveComponent("el-pagination")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_el_table, {
      class: "flex-1",
      data: _ctx.infos
    }, {
      default: _withCtx(() => [
        _createVNode(_component_el_table_column, {
          prop: "company_name",
          label: "公司名稱"
        }),
        _createVNode(_component_el_table_column, {
          prop: "mobile",
          label: "電話"
        }),
        _createVNode(_component_el_table_column, {
          prop: "contact",
          label: "聯絡人"
        }),
        _createVNode(_component_el_table_column, {
          prop: "email",
          label: "Email"
        }),
        _createVNode(_component_el_table_column, { label: "服務需求" }, {
          default: _withCtx((scope) => [
            _createElementVNode("div", _hoisted_2, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(scope.row.service, (service) => {
                return (_openBlock(), _createBlock(_component_el_tag, null, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(service), 1)
                  ]),
                  _: 2
                }, 1024))
              }), 256))
            ])
          ]),
          _: 1
        }),
        _createVNode(_component_el_table_column, {
          prop: "memo",
          label: "註記"
        }),
        _createVNode(_component_el_table_column, { label: "創建時間" }, {
          default: _withCtx((scope) => [
            _createElementVNode("span", null, _toDisplayString(_ctx.timestampToDateTime(scope.row.create_time)), 1)
          ]),
          _: 1
        })
      ]),
      _: 1
    }, 8, ["data"]),
    _createElementVNode("div", _hoisted_3, [
      _createVNode(_component_el_pagination, {
        "current-page": _ctx.page,
        "onUpdate:currentPage": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.page) = $event)),
        "page-size": _ctx.limit,
        "onUpdate:pageSize": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.limit) = $event)),
        layout: "prev, pager, next, sizes, total",
        total: _ctx.total
      }, null, 8, ["current-page", "page-size", "total"])
    ])
  ]))
}

import { mapMutations } from 'vuex';
import { defineComponent } from 'vue';
import { login, getProfile } from '@/api/auth';
import { AxiosError } from 'axios';

export default defineComponent({
  data() {
    return {
      account: 'admin',
      password: '',
    };
  },
  methods: {
    ...mapMutations(['setToken', 'setUserInfo']),
    async login() {
      await this.$execWithLoading(async () => {
        const rsp = await login({
          account: this.account,
          password: this.password,
        });
        const token = rsp?.data?.token;
        this.setToken(token);
        const profile = await getProfile();
        this.setUserInfo(profile);
        this.$router.push('/users');
      }, (e: Error | AxiosError) => {
        this.$showAxiosException(e);
      });
    },
  },
  mounted() {
    this.setUserInfo(undefined);
  },
});


import { ElNotification } from 'element-plus';
import { defineComponent } from 'vue';
import { AxiosError } from 'axios';
import {
  CompanyRequestData,
  CompanyResponseData,
  getCompanyData,
  updateCompany,
} from '@/api/company';
import { Delete } from '@element-plus/icons-vue';

type OfficeInput = {
  name: string,
  phone: string,
  fax: string,
  address: string,
}

export default defineComponent({
  props: {
    organization: {
      type: String,
      default: '',
    },
  },
  data() {
    const offices: OfficeInput[] = [];
    const chinaOffices: OfficeInput[] = [];
    const origData: CompanyResponseData = {
      uuid: '',
      name: '',
      slogan: '',
      introduction: '',
      description: '',
      seo: '',
      timeline: [],

      website: '',
      email: '',
      offices: [],
      chinaOffices: [],
      contactEmail: '',
    };
    return {
      form: {
        email: '',
        website: '',
      },
      offices,
      chinaOffices,
      origData,
      revert: true,
      Delete,
    };
  },
  methods: {
    deleteOffice(idx: number) {
      this.offices.splice(idx, 1);
    },
    deleteChinaOffice(idx: number) {
      this.chinaOffices.splice(idx, 1);
    },
    loadData() {
      this.$execWithLoading(async () => {
        await this.loadCompanyData();
      }, (e: Error | AxiosError) => {
        this.$showAxiosException(e);
      });
    },
    async loadCompanyData() {
      const rsp = await getCompanyData();
      this.origData = rsp;
      this.form = {
        email: rsp.email,
        website: rsp.website,
      };
      this.offices = rsp.offices.map((o) => ({ ...o }));
      this.chinaOffices = rsp.chinaOffices.map((o) => ({ ...o }));
    },
    reset() {
      this.offices = this.origData.offices.map((t) => ({
        ...t,
      }));
      this.chinaOffices = this.origData.chinaOffices.map((t) => ({
        ...t,
      }));
    },
    addOffice() {
      this.offices.unshift({
        name: '',
        address: '',
        phone: '',
        fax: '',
      });
    },
    addChinaOffice() {
      this.chinaOffices.unshift({
        name: '',
        address: '',
        phone: '',
        fax: '',
      });
    },
    async save() {
      const data: CompanyRequestData = {
        ...this.form,
        offices: this.offices,
        chinaOffices: this.chinaOffices,

        uuid: this.origData.uuid,
        name: this.origData.name,
        slogan: this.origData.slogan,
        introduction: this.origData.introduction,
        description: this.origData.description,
        timeline: this.origData.timeline,
        contactEmail: this.origData.contactEmail,
      };
      this.$execWithLoading(async () => {
        await updateCompany(this.origData.uuid, data);
        ElNotification({
          title: '更新成功',
          type: 'success',
        });
      }, (e: Error) => {
        this.$showAxiosException(e);
      });
    },
  },
  mounted() {
    this.loadData();
  },
});

/* eslint-disable no-param-reassign */
/* eslint-disable max-len */
import { App, Plugin } from 'vue';
import { ElNotification } from 'element-plus';
import i18n from './i18n';

const { t } = i18n.global;

const notifyPlugin: Plugin = {
  install(app: App) {
    app.config.globalProperties.$showError = function (key: string) {
      const msg = t(key) || key;
      ElNotification({
        title: msg,
        type: 'error',
      });
    };
  },
};

export default notifyPlugin;
